<template>
    <div class="page-content w-100">
        <div class="navbarmobileFix">
        <!-- start heading -->
        <div class="row">
            <div class="col-sm-12">
                <p class="text-center page-heading">Clinics using RxIx</p>
            </div>
        </div>
        <!-- end heading -->
        <div class="row box-items" style="margin-top:20px">
            <div class="col-opt" v-for="index in clinicConsult" :key="index" @click="sign(index)">
                <div class="col-fix mt-3" >
                    <div class="btn-text">{{ index.clinic_name }}</div>
                </div>
                <div>
                    <button class="btn btn-action">Pincode : {{ index.pincode }}</button>
                </div>
            </div>
            <div class="col-opt" v-for="index in homeConsult" :key="index" @click="homeclinicsign(index)">
              <div class="col-fix mt-3" >
                <div class="btn-text">{{ index.clinic_name }}</div>
              </div>
              <div>
                <button class="btn btn-action">Home Consulation</button>
              </div>
            </div>
        </div>
        </div>
    </div>
    <userfooter></userfooter>
</template>
<script>
import axios from 'axios';
import userfooter from "../user-footer.vue"
export default {
    components: {
    userfooter
    },
    data() {
        return {
            issign: false,
            clinicConsult:[],
            homeConsult: []
        }
    },
    created: function () {
        this.getDataConsultation();
    },
    methods: {
         sign(index) {
            const clinicID = index.id
            const doctorId =index.hcps_id.id
             this.$router.push(`/user/preview?hcpId=${doctorId}&clinicId=${clinicID}`)
        },
        homeclinicsign(index){
      const homeID = index.id
      const doctorId =index.hcps_id.id
      this.$router.push(`/homeConsultation/preview?hcpId=${doctorId}&homeId=${homeID}`)
    },
        async getDataConsultation() {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            axios
                .get(`${BASE_API_URL}/hcps/${localStorage.getItem('id')}/one`)
                .then((response) => {
                    this.userPayload = response.data
                })
            await axios
                .get(`${BASE_API_URL}/home-consultation`)
                .then((response) => {
                    this.homeConsult = response.data;
                })
            await axios
                .get(`${BASE_API_URL}/clinic-consultation`)
                .then((response) => {
                    this.clinicConsult = response.data;
                    this.clinicName = this.clinicConsult[0].clinic_name;
                })
        }
    }
}
</script>
<style>
.row.box-items {
    margin-top: 40px;
    display: grid;
    grid-gap: 40px;
    grid-template-columns: 22% 22% 22%;
    justify-content: center;
}
.col-opt {
    width: 100%;
    display: grid;
    justify-content: center;
}
.col-fix.mt-3 {
  cursor: pointer;
  flex-direction: column;
  border: 2px solid #00979e;
  width: 235px;
  height: auto;
  min-height: 115px;
  display: flex;
  justify-content: center;
  background-color: #fff;
  overflow: hidden;
}
.btn-text {
    text-align: center;
    padding: 15px;
    font-size: 22px;
}
button.btn.btn-action {
    background: #00979e;
    color: #fff;
    width: 235px;
    border-radius: inherit;
    padding: 0.375rem 0.75rem;
}
@media screen and (max-width:1400px) {
    .row.box-items {
        grid-gap: 75px
    }
}
@media screen and (max-width: 991px) {
    .navbarmobileFix {
    margin-bottom: 5rem;
  }
}
@media screen and (max-width: 768px){
    .row.box-items{
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0px;
    }
}
@media screen and (max-width: 620px){
.col-fix.mt-3 {
    width: 210px;
}
button.btn.btn-action {
    font-size: 17px;
    width: 210px;
  }
.btn-text {
    font-size: 20px;
  }
}
@media screen and (max-width:550px) {

.row.box-items{
      margin-top: 10px;
  }
 
}
@media screen and (max-width:470px) {

.col-fix.mt-3{
      width: 127px;
      min-height: 100px;
  }
  button.btn.btn-action {
    font-size: 12px;
    width: 127px;
  }
  .btn-text {
    font-size: 18px;
    padding:0px;
    margin: 0px;
  }
}
</style>
